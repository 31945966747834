import axios from 'axios'
import { API_CONST } from '../../../constants/api'
import { REDUX_CONST } from '../../constants/'

export const getDataFromZip =
	({ zip }) =>
	async (dispatch) => {
		// console.log('Events:Request:GetEvents')
		try {
			dispatch({
				type: REDUX_CONST.bluAutomation.locationConstants.getDataFromZip
					.request,
			})

			const config = {
				headers: {
					Authorization: `Bearer 7f2434c03592edfd077a78102b013bef0f206436`,
				},
				params: { zip },
			}

			const { data } = await axios.get(
				API_CONST.channels.bluAutomationV1 +
					API_CONST.routes.bluAutomation.getDataFromZip,
				config,
			)
			// console.log('Events:Response:GetEvents')
			// console.log(data)

			dispatch({
				type: REDUX_CONST.bluAutomation.locationConstants.getDataFromZip
					.success,
				payload: data,
			})
		} catch (error) {
			console.log(error)
			dispatch({
				type: REDUX_CONST.bluAutomation.locationConstants.getDataFromZip.fail,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
