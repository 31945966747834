import React, { useEffect, useState } from 'react'
import { FormStep, NavigationControls } from '../../../components/'

import { useLocation } from 'react-router-dom'
import { GEN_CONST } from '../../../constants/general'
import StepMessage from '../../../components/page-components/apps/customer-signup/step-message'
import { useDispatch, useSelector } from 'react-redux'
import { getDataFromZip, updateGHLContact } from '../../../redux/actions'

const TOTAL_STEPS = 4 // Assuming there are 3 steps including the final submit

const LeadFunnelForm = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const dataFromZipRdx = useSelector((state) => state.dataFromZip)
	const updateGHLContactRdx = useSelector((state) => state.updateGHLContact)

	const [currentStep, setCurrentStep] = useState(1)
	const [formData, setFormData] = useState({
		firstName: '',
		serviceType: GEN_CONST.marketingServiceGroups.WEEKLY,
		phone: '',
		email: '',
		address: '',
		city: '',
		state: '',
		zip: null,
		source: '',
		contactOption: GEN_CONST.contactTypes.TEXT,
	})
	const [validatedStep, setValidatedStep] = useState({ 1: 0, 2: 0, 3: 0 })
	const [canGoNext, setCanGoNext] = useState(false)

	const testUrlZip = (zip) => {
		if (zip && zip.length > 4) {
			return true
		} else {
			return false
		}
	}

	useEffect(() => {
		if (dataFromZipRdx?.data) {
			let city = ''
			let state = ''
			if (dataFromZipRdx?.data?.city) {
				city = dataFromZipRdx.data.city
			}

			if (dataFromZipRdx?.data?.state) {
				state = dataFromZipRdx.data.state
			}
			setFormData({ ...formData, city, state })
		} else {
			setFormData({
				...formData,
				city: '',
				state: '',
			})
		}
	}, [dataFromZipRdx])

	useEffect(() => {
		if (formData?.zip?.toString().length > 4) {
			dispatch(getDataFromZip({ zip: formData.zip.toString() }))
		} else {
			setFormData({ ...formData, city: '', state: '' })
		}
	}, [formData.zip])

	// Grab parameters from URL
	useEffect(() => {
		if (location && location.search) {
			const params = new URLSearchParams(location.search)
			const urlZip = params.get(GEN_CONST.urlParameters.ZIP)
				? params.get(GEN_CONST.urlParameters.ZIP)
				: null
			const urlSrc = params.get(GEN_CONST.urlParameters.SOURCE)
				? params.get(GEN_CONST.urlParameters.SOURCE)
				: ''

			const step = testUrlZip(urlZip) ? 2 : 1
			setCurrentStep(step)
			setValidatedStep({ ...validatedStep, 1: testUrlZip(urlZip) ? 1 : 0 })
			setFormData({
				...formData,
				zip: urlZip ? parseInt(urlZip) : null,
				source: urlSrc,
			})
		}

		return () => {}
	}, [location])

	const postContactToGHL = () => {
		const preferredCommunicationMethod =
			formData.contactOption === GEN_CONST.contactTypes.CALL
				? 'Phone'
				: formData.contactOption === GEN_CONST.contactTypes.EMAIL
				? 'Email'
				: 'Text'

		const contact = JSON.stringify({
			contact: {
				fn: formData.firstName,
				em: formData.email,
				ph: formData.phone,
				cttyp: preferredCommunicationMethod,
				src: formData.source === '' ? 'ORGANIC' : formData.source,
				ad: formData.address,
				cy: formData.city,
				st: formData.state,
				zip: formData.zip ? formData.zip.toString() : '',
				styp:
					formData.serviceType === GEN_CONST.marketingServiceGroups.WEEKLY
						? 'Weekly'
						: formData.serviceType,
			},
		})
		dispatch(updateGHLContact({ contact }))
	}

	const goNext = () => {
		if (currentStep === 2 || currentStep === 3) {
			postContactToGHL()
		}

		if (currentStep < TOTAL_STEPS) {
			setCurrentStep(currentStep + 1)
		}
	}

	const goBack = () => {
		if (currentStep > 1) {
			setCurrentStep(currentStep - 1)
		}
	}

	const handleChange = (e) => {
		// console.log('handleChange', e)
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}

	const handleSubmit = () => {
		// Redirect based on service type
		const redirectUrl = `https://www.amazingblu.com/${
			formData.serviceType === GEN_CONST.marketingServiceGroups.WEEKLY
				? 'checkout'
				: 'thank-you'
		}?${new URLSearchParams({
			[GEN_CONST.urlParameters.SERVICE_TYPE]: formData.serviceType,
			[GEN_CONST.urlParameters.SOURCE]: formData.source,
			[GEN_CONST.urlParameters.FNAME]: formData.firstName,
			[GEN_CONST.urlParameters.PHONE]: formData.phone,
			[GEN_CONST.urlParameters.EMAIL]: formData.email,
			[GEN_CONST.urlParameters.ADDRESS]: formData.address,
			[GEN_CONST.urlParameters.CITY]: formData.city,
			[GEN_CONST.urlParameters.STATE]: formData.state,
			[GEN_CONST.urlParameters.ZIP]: formData.zip,
			[GEN_CONST.urlParameters.CONTACT_TYPE]: formData.contactOption,
		}).toString()}`
		window.top.location.href = redirectUrl
	}
	const handleSetValidation = (obj) => {
		//console.log(obj)
		setValidatedStep((prev) => ({ ...prev, [obj.key]: obj.value }))
	}

	useEffect(() => {
		if (validatedStep[currentStep] === 1 && currentStep < TOTAL_STEPS) {
			setCanGoNext(true)
		} else {
			setCanGoNext(false)
		}
	}, [validatedStep, currentStep])

	return (
		<div>
			<div className=' p-5 text-left  rounded-lg relative max-w-3xl'>
				<div className='container max-w-lg mx-auto bg-transparent rounded-lg min-h-52'>
					<div className='h-[350px]'>
						<StepMessage data={formData} step={currentStep} />
						<FormStep
							step={currentStep}
							data={formData}
							onChange={handleChange}
							validatedStep={validatedStep}
							onUpdateValidation={handleSetValidation}
						/>
					</div>
					<NavigationControls
						onSubmit={handleSubmit}
						step={currentStep}
						onBack={goBack}
						onNext={goNext}
						canGoNext={canGoNext}
						canGoBack={currentStep > 1}
					/>
				</div>
			</div>
		</div>
	)
}

export default LeadFunnelForm
