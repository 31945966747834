import axios from 'axios'
import { API_CONST } from '../../../constants/api'
import { REDUX_CONST } from '../../constants/'

export const updateGHLContact =
	({ contact }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: REDUX_CONST.bluAutomation.ghlConstants.updateGHLContact.request,
			})

			const config = {
				headers: {
					Authorization: `Bearer 7f2434c03592edfd077a78102b013bef0f206436`,
				},
			}

			const { data } = await axios.post(
				`${API_CONST.channels.bluAutomationV1}${API_CONST.routes.bluAutomation.updateGHLContact}`,
				JSON.parse(contact),
				config,
			)

			dispatch({
				type: REDUX_CONST.bluAutomation.ghlConstants.updateGHLContact.success,
				payload: data,
			})
		} catch (error) {
			console.log(error)
			dispatch({
				type: REDUX_CONST.bluAutomation.ghlConstants.updateGHLContact.fail,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
