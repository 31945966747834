//navigation-controls.js
import React from 'react'
import { ICONS_DEFAULT } from '../../../../constants/icons'

const NavigationControls = ({
	onBack,
	onNext,
	canGoNext,
	canGoBack,
	step,
	onSubmit,
}) => {
	return (
		<div className='flex flex-col space-y-2 items-center '>
			<button
				onClick={step === 4 ? onSubmit : onNext}
				disabled={step === 4 ? false : !canGoNext}
				className={`${
					canGoNext
						? 'bg-bluDeepBlue hover:bg-bluDarkTeal text-white'
						: step === 4
						? 'bg-bluOrange hover:bg-bluDarkOrange text-white font-bold'
						: 'bg-gray-300 text-gray-500 font-normal'
				} py-2 px-4 rounded-lg w-full`}
			>
				{step === 4 ? 'Submit' : 'NEXT'}
			</button>

			{canGoBack && (
				<div className='flex justify-start w-full'>
					<button
						onClick={onBack}
						className={`flex items-center  space-x-1 w-100 text-left text-sm 
						font-normal text-gray-400 py-2 px-4 rounded-sm`}
					>
						<div className='text-xl'>{ICONS_DEFAULT.backArrow}</div>
						<div>Back</div>
					</button>
				</div>
			)}
		</div>
	)
}

export default NavigationControls
