//step-three.js
import React, { useEffect } from 'react'
const ADDRESS_MESSAGE = ''
const ZIP_MESSAGE = ''

const messages = {
	address: ADDRESS_MESSAGE,
	zip: ZIP_MESSAGE,
	city: 'City, State',
	state: 'Enter a valid zip',
}
const StepThree = ({ data, onChange, onUpdateValidation }) => {
	useEffect(() => {
		let isValid = true

		if (data.address === '') {
			isValid = false
		}

		if (data.zip && data.zip.toString().length < 5) {
			isValid = false
		}
		if (data.city === '' || data.state === '') {
			isValid = false
		}

		if (isValid) {
			onUpdateValidation({ key: 3, value: 1 })
		} else {
			onUpdateValidation({ key: 3, value: 0 })
		}
	}, [data])

	const validateZip = (e) => {
		// Basic cleanup to remove all non-digit characters for processing and sending back
		const numericValue = e.target.value.replace(/\D/g, '') // Removes everything except digits
		// Check length for validation or further conditions
		if (numericValue.length < 6) {
			// Call onChange with only numeric values
			onChange({ target: { name: 'zip', value: parseInt(numericValue) } })
		}
	}
	const sharedInputClass =
		'flex flex-col w-full space-y-0 space-x-0  pt-1 items-center justify-center'

	const labelClass =
		' bg-white rounded-t-sm w-full pl-4 pt-2 pb-0 font-semibold text-xs text-gray-400 '
	const inputClass =
		' w-full flex-1 py-1 font-semibold px-4 border-b text-bluDeepBlue border-bluDarkTeal mb-4  focus:outline-none '

	return (
		<div className='max-w-xs mx-auto '>
			<div className='mb-4'>
				<div className={sharedInputClass}>
					<div className={labelClass}>Street Address *</div>
					<input
						type='text'
						name='address'
						id='address'
						value={data.address || ''}
						onChange={onChange}
						className={inputClass}
						required
					/>
				</div>
			</div>
			<div className='flex justify-between mb-4'>
				<div className='flex flex-col w-full space-y-0 space-x-0 pt-1 items-start justify-center '>
					<div className='bg-white rounded-t-sm w-full pl-4 pt-2 pb-0 font-semibold text-xs text-gray-400'>
						City, State
					</div>
					<div className='pl-4 bg-white w-full text-left border-b border-bluDarkTeal text-bluDeepBlue font-semibold'>
						{data.city?.length > 1
							? `${data.city}, ${data.state}`
							: `${messages.state}`}
					</div>
				</div>
			</div>
			<div className='mb-4'>
				<div className={sharedInputClass}>
					<div className={labelClass}>Zip Code *</div>
					<input
						type='number'
						name='zip2'
						id='zip2'
						value={data.zip || ''}
						onChange={validateZip}
						className={inputClass}
						maxLength='5' // Ensures only 5 characters can be entered
						pattern='\d{5}' // Ensures only five digits can be entered
						title='Please enter a 5-digit zip code' // Tooltip on hover over the input field when invalid
						required
					/>
				</div>
			</div>
		</div>
	)
}

export default StepThree
