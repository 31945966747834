//MAIN CHANNEL  FOR ELECTRON
export const API_COMMUNICATIONS = 'API_COMMUNICATIONS'
export const MAIN_COMMUNICATIONS = 'MAIN_COMMUNICATIONS'

export const API_CONST = {
	channels: {
		bluAutomationV1: '/api/bluAutomation/v1/',
	},
	routes: {
		home: '/',
		bluAutomation: {
			getDataFromZip: '/get-data-from-zip',
			updateGHLContact: '/update-ghl-contact',
		},
	},
}
