//form-step.js
import React from 'react'
import {
	SignUpStepOne,
	SignUpStepThree,
	SignUpStepTwo,
	FinalSubmit,
} from '../../../index'

const FormStep = ({ step, data, onChange, onUpdateValidation }) => {
	const renderStepContent = () => {
		switch (step) {
			case 1:
				return (
					<SignUpStepOne
						onChange={onChange}
						data={data}
						onUpdateValidation={onUpdateValidation}
					/>
				)
			case 2:
				return (
					<SignUpStepTwo
						onChange={onChange}
						data={data}
						onUpdateValidation={onUpdateValidation}
					/>
				)
			case 3:
				return (
					<SignUpStepThree
						onChange={onChange}
						data={data}
						onUpdateValidation={onUpdateValidation}
					/>
				)
			case 4:
				return (
					<FinalSubmit
						onChange={onChange}
						data={data}
						onUpdateValidation={onUpdateValidation}
					/>
				)
			default:
				return (
					<div className='max-w-lg mx-auto my-10 p-6 border-2 border-gray-200 rounded-lg shadow'>
						Unknown Step
					</div>
				)
		}
	}

	return <div className={`my-2 `}>{renderStepContent()}</div>
}

export default FormStep
