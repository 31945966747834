//step-message.js
import React, { useEffect, useState } from 'react'
import { GEN_CONST } from '../../../../constants/general'

const initialMessage =
	'Why Wait? Select your service and how we should contact you to see your deal.'

const WeeklyServiceMessage = ({ data }) => {
	if (data?.serviceType === GEN_CONST.marketingServiceGroups.REPAIR) {
		return (
			<>
				<span className='font-bold text-bluDeepBlue'>Great News! </span>
				Enjoy up to
				<span className='text-bluDeepBlue font-bold'>{` 20% off your first repair `}</span>{' '}
				when you join your neighbors in choosing AmazingBlu!
			</>
		)
	}
	if (data?.serviceType === GEN_CONST.marketingServiceGroups.COMMERCIAL) {
		return (
			<>
				<span className='font-bold text-bluDeepBlue'>
					Learn more about how our services can benefit your commercial
					property.
				</span>
			</>
		)
	}

	return (
		<>
			<span className='font-bold text-bluDeepBlue'>Great News! </span>
			Join your neighbors in signing up for our weekly service, and enjoy your
			<span className='text-bluDeepBlue font-bold'> first month</span> at just
			<span className='text-bluDeepBlue font-bold'>{` $59.99.`}</span>
		</>
	)
}

const StepMessage = ({ step, data }) => {
	const [message, setMessage] = useState({
		header: 'Unlock Your Deal',
		paragraph: initialMessage,
		callToAction: 'Enter Your Zip!',
	})

	useEffect(() => {
		switch (step) {
			case 1:
				setMessage({
					header: 'Unlock Your Deal',
					paragraph: (
						<span className='font-bold text-bluDeepBlue'>{initialMessage}</span>
					),
					callToAction: 'Enter Your Zip!',
				})
				break
			case 2:
				setMessage({
					header:
						data?.serviceType === GEN_CONST.marketingServiceGroups.COMMERCIAL
							? `Discover Our Commercial Services`
							: `Claim Your Neighbor's Favorite Deal`,
					paragraph: <WeeklyServiceMessage data={data} />,
					callToAction: 'Sign Up Below!',
				})
				break
			case 3:
				setMessage({
					header: 'Almost There!',
					paragraph:
						'Tell us where the pool is located and we will handle the rest.',
					callToAction: '',
				})
				break
			case 4:
				setMessage({
					header: 'Prepare to Relax!',
					paragraph: 'Please check your information below.',
					callToAction: '',
				})
				break

			default:
				break
		}
	}, [step])

	return (
		<div className=''>
			<h2 className='font-semibold mb-2 text-lg md:text-2xl text-center text-bluDeepBlue '>
				{message.header}
			</h2>
			{message.paragraph !== '' && (
				<p className=' text-sm max-w-96 mx-auto text-gray-700  text-center'>
					{message.paragraph}
				</p>
			)}
		</div>
	)
}

export default StepMessage
