import { REDUX_CONST } from '../../constants'

export const getDataFromZip = (state = { data: {} }, action) => {
	switch (action.type) {
		case REDUX_CONST.bluAutomation.locationConstants.getDataFromZip.request:
			return { loading: true }
		case REDUX_CONST.bluAutomation.locationConstants.getDataFromZip.success:
			return { data: action.payload }
		case REDUX_CONST.bluAutomation.locationConstants.getDataFromZip.fail:
			return { error: action.payload }
		default:
			return state
	}
}
