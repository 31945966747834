import { REDUX_CONST } from '../../constants'

export const updateGHLContact = (state = { data: {} }, action) => {
	switch (action.type) {
		case REDUX_CONST.bluAutomation.ghlConstants.updateGHLContact.request:
			return { loading: true }
		case REDUX_CONST.bluAutomation.ghlConstants.updateGHLContact.success:
			return { data: action.payload }
		case REDUX_CONST.bluAutomation.ghlConstants.updateGHLContact.fail:
			return { error: action.payload }
		default:
			return state
	}
}
