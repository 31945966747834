export const GET_DATA_FROM_ZIP_REQUEST = 'GET_DATA_FROM_ZIP_REQUEST'
export const GET_DATA_FROM_ZIP_SUCCESS = 'GET_DATA_FROM_ZIP_SUCCESS'
export const GET_DATA_FROM_ZIP_FAIL = 'GET_DATA_FROM_ZIP_FAIL'

export const locationConstants = {
	getDataFromZip: {
		request: GET_DATA_FROM_ZIP_REQUEST,
		success: GET_DATA_FROM_ZIP_SUCCESS,
		fail: GET_DATA_FROM_ZIP_FAIL,
	},
}
