//service-options-step-one.js

import React, { useState } from 'react'
import { GEN_CONST } from '../../../../constants/general'
import {
	ICONS_CONSTRUCTION,
	ICONS_DATA,
	ICONS_DEFAULT,
} from '../../../../constants/icons'

const buttonClass =
	'flex w-20 h-20 rounded-lg  hover:bg-bluMediumTeal items-center justify-center text-5xl font-bold text-white'
const inactiveButtonClass = ' bg-bluTeal  '
const activeButtonClass = ' bg-bluDarkTeal font-semibold '

const ServiceOptions = ({ messages, data, onChange }) => {
	const [hoverItem, setHoverItem] = useState(null)
	const touchHandler = (type) => setHoverItem(type)
	const changeHandler = (type) =>
		onChange({ target: { name: 'serviceType', value: type } })

	return (
		<div className='mb-4 '>
			<div className='flex flex-col w-full space-y-0 space-x-0 items-center justify-center '>
				<div className='w-full text-xs flex justify-center items-center space-x-6 pt-2 px-4 focus:outline-none'>
					<button
						className={`${buttonClass} ${
							data.serviceType === GEN_CONST.marketingServiceGroups.WEEKLY
								? activeButtonClass
								: inactiveButtonClass
						}`}
						onClick={() =>
							changeHandler(GEN_CONST.marketingServiceGroups.WEEKLY)
						}
						onTouchStart={() =>
							touchHandler(GEN_CONST.marketingServiceGroups.WEEKLY)
						}
						onTouchEnd={() => touchHandler(null)}
						onMouseOver={() =>
							touchHandler(GEN_CONST.marketingServiceGroups.WEEKLY)
						}
						onMouseOut={() => touchHandler(null)}
					>
						{hoverItem &&
						hoverItem === GEN_CONST.marketingServiceGroups.WEEKLY ? (
							<div className='text-xs'>
								{GEN_CONST.marketingServiceGroups.WEEKLY}
							</div>
						) : (
							<div>{ICONS_DATA.calendarRange}</div>
						)}
					</button>
					<button
						className={`${buttonClass}  ${
							data?.serviceType === GEN_CONST.marketingServiceGroups.REPAIR
								? activeButtonClass
								: inactiveButtonClass
						}`}
						onClick={() =>
							changeHandler(GEN_CONST.marketingServiceGroups.REPAIR)
						}
						onTouchStart={() =>
							touchHandler(GEN_CONST.marketingServiceGroups.REPAIR)
						}
						onTouchEnd={() => touchHandler(null)}
						onMouseOver={() =>
							touchHandler(GEN_CONST.marketingServiceGroups.REPAIR)
						}
						onMouseOut={() => touchHandler(null)}
					>
						{hoverItem &&
						hoverItem === GEN_CONST.marketingServiceGroups.REPAIR ? (
							<div className='text-xs'>
								{GEN_CONST.marketingServiceGroups.REPAIR}
							</div>
						) : (
							<div>{ICONS_DEFAULT.gear}</div>
						)}
					</button>
					<button
						className={`${buttonClass}  ${
							data?.serviceType === GEN_CONST.marketingServiceGroups.COMMERCIAL
								? activeButtonClass
								: inactiveButtonClass
						}`}
						onClick={() =>
							changeHandler(GEN_CONST.marketingServiceGroups.COMMERCIAL)
						}
						onTouchStart={() =>
							touchHandler(GEN_CONST.marketingServiceGroups.COMMERCIAL)
						}
						onTouchEnd={() => touchHandler(null)}
						onMouseOver={() =>
							touchHandler(GEN_CONST.marketingServiceGroups.COMMERCIAL)
						}
						onMouseOut={() => touchHandler(null)}
					>
						{hoverItem &&
						hoverItem === GEN_CONST.marketingServiceGroups.COMMERCIAL ? (
							<div className='text-xs'>
								{GEN_CONST.marketingServiceGroups.COMMERCIAL}
							</div>
						) : (
							<div>{ICONS_CONSTRUCTION.office}</div>
						)}
					</button>
				</div>
			</div>
		</div>
	)
}

export default ServiceOptions
