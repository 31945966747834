export const CONST_DARK = 'Dark'
export const CONST_LIGHT = 'Light'

export const GEN_CONST = {
	urlParameters: {
		SERVICE_TYPE: 'styp',
		FNAME: 'fn',
		LNAME: 'ln',
		EMAIL: 'em',
		PHONE: 'ph',
		ADDRESS: 'ad',
		CITY: 'cy',
		STATE: 'st',
		ZIP: 'zip',
		STEP: 'stp',
		SOURCE: 'src',
		CONTACT_TYPE: 'cttyp',
	},
	contactTypes: { TEXT: 'TEXT', EMAIL: 'EMAIL', CALL: 'CALL' },
	general: {
		DARK: CONST_DARK,
		LIGHT: CONST_LIGHT,
		NEXT: 'next',
		BACK: 'back',
	},
	marketingServiceGroups: {
		WEEKLY: 'Weekly Service',
		REPAIR: 'Repair',
		COMMERCIAL: 'Commercial',
	},
}

export const CONST_COLORS = {
	darkBlue: 'hsl(217, 28%, 15%)',
	darkBlue1: 'hsl(218, 28%, 13%)',
	darkBlue2: 'hsl(216, 53%, 9%)',
	darkBlue3: 'hsl(219, 30%, 18%)',
	// romanRed1: 'hsl(176, 68%, 64%)',
	accentBlue: 'hsl(198, 60%, 50%)',
	lightRed: 'hsl(0, 100%, 63%)',
	cyan: 'hsl(180, 66%, 49%)',
	cyanLight: 'hsl(180, 66%, 69%)',
	darkViolet: 'hsl(257, 27%, 26%)',
	red: 'hsl(0, 87%, 67%)',
	grayishViolet: 'hsl(257, 7%, 63%)',
	veryDarkBlue: 'hsl(255, 11%, 22%)',
	veryDarkViolet: 'hsl(260, 8%, 14%)',
}
