//step-one.js
import React, { useEffect } from 'react'
import ServiceOptions from './service-options-step-one'
import ContactOptions from './contact-options'
const NAME_MESSAGE = 'Please enter at least three characters.'
const PHONE_MESSAGE = 'Please enter a 10 digit phone number.'
const EMAIL_MESSAGE = 'Please enter a valid email address.'
const defaultMessages = {
	name: NAME_MESSAGE,
	phone: PHONE_MESSAGE,
	email: EMAIL_MESSAGE,
}
const StepOne = ({ data, onChange, onUpdateValidation }) => {
	//const [messages, setMessages] = useState(defaultMessages)

	useEffect(() => {
		if (data.contactOption) {
			onUpdateValidation({ key: 1, value: 1 })
		} else {
			// console.log(data)
			onUpdateValidation({ key: 1, value: 0 })
		}
	}, [data])

	return (
		<div className='max-w-xl mx-auto pt-5 flex flex-col space-y-0 text-center items-center justify-center '>
			<ServiceOptions
				messages={defaultMessages}
				data={data}
				onChange={onChange}
			/>
			<ContactOptions data={data} onChange={onChange} />
		</div>
	)
}

export default StepOne
