//contact-options.js

import React, { useState } from 'react'
import { GEN_CONST } from '../../../../constants/general'
import { ICONS_DEFAULT } from '../../../../constants/icons'

const buttonClass =
	' flex w-20 h-20 rounded-lg  hover:bg-bluMediumTeal items-center justify-center text-5xl font-bold text-white '
const inactiveButtonClass = ' bg-bluTeal '
const activeButtonClass = ' bg-bluDarkTeal font-semibold '

const ContactOptions = ({ data, onChange }) => {
	const [hoverItem, setHoverItem] = useState(null)
	const touchHandler = (type) => setHoverItem(type)
	const changeHandler = (type) =>
		onChange({ target: { name: 'contactOption', value: type } })

	return (
		<div className='mb-4 pl-12'>
			<div className='flex flex-col w-full space-y-0 space-x-0  items-center justify-center '>
				<div className='w-full text-xs flex justify-center items-center space-x-6 pt-2 px-4 focus:outline-none'>
					<button
						className={`${buttonClass} ${
							data.contactOption === GEN_CONST.contactTypes.TEXT
								? activeButtonClass
								: inactiveButtonClass
						}`}
						onClick={() => changeHandler(GEN_CONST.contactTypes.TEXT)}
						onTouchStart={() => touchHandler(GEN_CONST.contactTypes.TEXT)}
						onTouchEnd={() => touchHandler(null)}
						onMouseOver={() => touchHandler(GEN_CONST.contactTypes.TEXT)}
						onMouseOut={() => touchHandler(null)}
					>
						{hoverItem && hoverItem === GEN_CONST.contactTypes.TEXT ? (
							<div className='text-xs'>{GEN_CONST.contactTypes.TEXT}</div>
						) : (
							<div>{ICONS_DEFAULT.text}</div>
						)}

						{/* {GEN_CONST.contactTypes.TEXT} */}
					</button>
					<button
						className={`${buttonClass}  ${
							data.contactOption === GEN_CONST.contactTypes.CALL
								? activeButtonClass
								: inactiveButtonClass
						}`}
						onClick={() => changeHandler(GEN_CONST.contactTypes.CALL)}
						onTouchStart={() => touchHandler(GEN_CONST.contactTypes.CALL)}
						onTouchEnd={() => touchHandler(null)}
						onMouseOver={() => touchHandler(GEN_CONST.contactTypes.CALL)}
						onMouseOut={() => touchHandler(null)}
					>
						{hoverItem && hoverItem === GEN_CONST.contactTypes.CALL ? (
							<div className='text-xs'>{GEN_CONST.contactTypes.CALL}</div>
						) : (
							<div>{ICONS_DEFAULT.phone}</div>
						)}

						{/* {GEN_CONST.contactTypes.CALL} */}
					</button>
					<button
						className={`${buttonClass}  ${
							data.contactOption === GEN_CONST.contactTypes.EMAIL
								? activeButtonClass
								: inactiveButtonClass
						}`}
						onClick={() => changeHandler(GEN_CONST.contactTypes.EMAIL)}
						onTouchStart={() => touchHandler(GEN_CONST.contactTypes.EMAIL)}
						onTouchEnd={() => touchHandler(null)}
						onMouseOver={() => touchHandler(GEN_CONST.contactTypes.EMAIL)}
						onMouseOut={() => touchHandler(null)}
					>
						{hoverItem && hoverItem === GEN_CONST.contactTypes.EMAIL ? (
							<div className='text-xs'>{GEN_CONST.contactTypes.EMAIL}</div>
						) : (
							<div>{ICONS_DEFAULT.email}</div>
						)}

						{/* {GEN_CONST.contactTypes.EMAIL} */}
					</button>
				</div>
			</div>
		</div>
	)
}

export default ContactOptions
