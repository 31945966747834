//request-service.js
import React, { useState, useEffect } from 'react'
import { ICONS_DEFAULT } from '../../../constants/icons'
import { useLocation } from 'react-router-dom'
import { GEN_CONST } from '../../../constants/general'

const RequestService = () => {
	const [zipCode, setZipCode] = useState('')

	const [source, setSource] = useState('ORGANIC')
	const location = useLocation()

	// Grab parameters from URL
	useEffect(() => {
		if (location && location.search) {
			const params = new URLSearchParams(location.search)
			const source = params.get(GEN_CONST.urlParameters.SOURCE)
				? params.get(GEN_CONST.urlParameters.SOURCE)
				: ''

			setSource(source)
		}

		return () => {}
	}, [location])

	const handleInputChange = (event) => {
		const { value } = event.target
		// Regular expression to allow only numbers
		if (value === '' || (/^\d+$/.test(value) && value.length <= 5)) {
			setZipCode(value)
		}
	}

	const handleSubmit = (event) => {
		event.preventDefault()
		if (zipCode.length === 5) {
			console.log('Zip Code Submitted:', zipCode)
			// Add logic to handle the zip code submission here
			window.top.location.href = `https://www.amazingblu.com/offers?${GEN_CONST.urlParameters.ZIP}=${zipCode}&${GEN_CONST.urlParameters.SOURCE}=${source}`
			// @todo wire up to back-end, notify and database storage IP address, Zip Code, Cookies, media type
		}
	}

	return (
		<div
			className='bg-slate-800 bg-opacity-50 px-6 py-3 rounded-lg shadow-lg max-w-lg'
			style={{ backdropFilter: 'blur(10px)' }}
		>
			<form
				onSubmit={handleSubmit}
				className='flex flex-col space-y-4 space-x-0  md:flex-row md:space-x-4 md:space-y-0 items-center justify-center '
			>
				<input
					type='text'
					value={zipCode}
					onChange={handleInputChange}
					placeholder='Enter Your Zip Code'
					className='w-full md:w-1/2 flex-1 py-2 px-4 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-bluOrange focus:border-transparent'
					maxLength='5' // Ensures only 5 characters can be entered
					pattern='\d{5}' // Ensures only five digits can be entered
					title='Please enter a 5-digit zip code' // Tooltip on hover over the input field when invalid
					required
				/>
				<button
					type='submit'
					className='w-full md:w-1/2 justify-center bg-bluOrange hover:bg-bluDarkOrange text-white font-bold py-2 px-0 md:px-4 rounded flex items-center space-x-3'
				>
					<div className='text-md'>Request Service</div>
					<div className='text-lg'>{ICONS_DEFAULT.forwardArrow}</div>
				</button>
			</form>
		</div>
	)
}

export default RequestService
