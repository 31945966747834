//store.js
import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import {
	getEvents,
	getLeads,
	getSales,
	getJobs,
	getPhases,
	createPhase,
	deletePhase,
	updatePhase,
	getDataFromZip,
	updateGHLContact,
} from './reducers/index'

const reducer = combineReducers({
	sales: getSales,
	leads: getLeads,
	events: getEvents,
	jobs: getJobs,
	dataFromZip: getDataFromZip,
	updateGHLContact,
	phases: getPhases,
	createPhase,
	updatePhase,
	deletePhase,
})

const initialState = {}

const middleware = [thunk]

const store = createStore(
	reducer,
	initialState,
	composeWithDevTools(applyMiddleware(...middleware)),
)

export default store
