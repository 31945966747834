//final-submit.js
import React, { useEffect, useState } from 'react'
import { GEN_CONST } from '../../../../constants/general'

const FinalSubmit = ({ data }) => {
	const [phone, setPhone] = useState(data?.phone || '')

	useEffect(() => {
		if (data?.phone) {
			const formatPhone = (number) => {
				if (!number) return ''

				number = number.replace(/\D/g, '')

				let areaCode = ''
				let mid = ''
				let last = ''

				if (number.length >= 1 && number.length <= 3) {
					areaCode = number.substring(0, 3)
				} else if (number.length >= 4 && number.length <= 6) {
					areaCode = number.substring(0, 3)
					mid = number.substring(3, 6)
				} else if (number.length > 6) {
					areaCode = number.substring(0, 3)
					mid = number.substring(3, 6)
					last = number.substring(6, 10)
				}

				let formattedNumber = ''
				if (areaCode && !mid) formattedNumber += `${areaCode}`
				if (areaCode && mid) formattedNumber += `(${areaCode}) ${mid}`
				if (last) formattedNumber += `-${last}`

				return formattedNumber
			}
			setPhone(formatPhone(data.phone))
		}
	}, [data])

	const listItemClass = 'flex space-x-3'
	const elementClass = ' text-left'
	const labelClass = 'font-bold text-bluDeepBlue'
	return (
		<div className='max-w-sm mx-auto pl-6 text-sm'>
			<div className=' space-y-1 my-4 w-3/4 md:w-3/5 mx-auto'>
				<div className={listItemClass}>
					<div className={labelClass}>First Name:</div>
					<div className={elementClass}> {data.firstName}</div>
				</div>
				<div className={listItemClass}>
					<div className={labelClass}>Service Type:</div>
					<div className={elementClass}> {data.serviceType}</div>
				</div>

				<div className={listItemClass}>
					<div className={labelClass}>Phone:</div>{' '}
					<div className={elementClass}>{phone}</div>
				</div>
				<div className={listItemClass}>
					<div className={labelClass}>Email:</div>
					<div className={elementClass}> {data.email}</div>
				</div>
				<div className={listItemClass}>
					<div className={labelClass}>Address:</div>{' '}
					<div className={elementClass}>{data.address}</div>
				</div>
				<div className={listItemClass}>
					<div className={labelClass}>City:</div>{' '}
					<div className={elementClass}>{data.city}</div>
				</div>
				<div className={listItemClass}>
					<div className={labelClass}>State:</div>{' '}
					<div className={elementClass}>{data.state}</div>
				</div>
				<div className={listItemClass}>
					<div className={labelClass}>Zip Code:</div>
					<div className={elementClass}> {data.zip}</div>
				</div>
				<div className={listItemClass}>
					<div className={labelClass}>Contact via:</div>
					<div className={elementClass}>
						{data.contactOption === GEN_CONST.contactTypes.TEXT
							? 'Text'
							: data.contactOption === GEN_CONST.contactTypes.EMAIL
							? 'Email'
							: 'Call'}
					</div>
				</div>
			</div>
		</div>
	)
}

export default FinalSubmit
