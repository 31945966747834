import React, { useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { LeadFunnel, RequestService } from './pages/'
import { CONST_LOCAL_STORAGE, GREEN_PALETTE, ROUTES } from './constants/'
import './App.css'
import { useStateContext } from './contexts/ContextProvider'
import { CONST_LIGHT } from './constants/general'

const App = () => {
	const { setCurrentPalette, setCurrentMode } = useStateContext()

	useEffect(() => {
		const currentThemePalette = localStorage.getItem('colorPalette')
		const currentThemeMode = localStorage.getItem(CONST_LOCAL_STORAGE.themeMode)

		if (currentThemeMode && currentThemeMode !== 'undefined') {
			setCurrentMode(currentThemeMode)
		} else {
			setCurrentMode(CONST_LIGHT)
		}

		if (currentThemePalette && currentThemePalette !== 'undefined') {
			setCurrentPalette(JSON.parse(currentThemePalette))
		} else {
			setCurrentPalette(GREEN_PALETTE)
		}
	}, [setCurrentMode, setCurrentPalette])

	const routes = (
		<div className={'bg-transparent w-full min-h-screen  '}>
			<Routes>
				<Route path={ROUTES.apps.requestService} element={<RequestService />} />
				<Route path={ROUTES.apps.leadFunnel} element={<LeadFunnel />} />

				<Route
					path='*'
					element={<Navigate to={ROUTES.general.home} replace />}
				/>
			</Routes>
		</div>
	)

	return (
		<div className='select-none flex relative dark:bg-main-dark-bg'>
			{routes}
		</div>
	)
}

export default App
