//step-two.js
import React, { useEffect, useState } from 'react'
const NAME_MESSAGE = 'Please enter at least three characters.'
const PHONE_MESSAGE = 'Please enter a 10 digit phone number.'
const EMAIL_MESSAGE = 'Please enter a valid email address.'
const defaultMessages = {
	name: NAME_MESSAGE,
	phone: PHONE_MESSAGE,
	email: EMAIL_MESSAGE,
}
const StepTwo = ({ data, onChange, onUpdateValidation }) => {
	const [messages, setMessages] = useState(defaultMessages)

	useEffect(() => {
		let isValid = true
		let messages = { ...defaultMessages }

		if (data.firstName.length >= 3) {
			messages.name = ''
		} else if (data.firstName === '') {
			messages.name = ''
			isValid = false
		} else {
			messages.name = 'Please enter at least three characters'
			isValid = false
		}

		if (data.phone.length === 10) {
			messages.phone = ''
		} else if (data.phone === '') {
			messages.phone = ''
			isValid = false
		} else {
			messages.phone = 'Please enter a valid phone number'
			isValid = false
		}

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		if (emailRegex.test(data.email)) {
			messages.email = ''
		} else if (data.email === '') {
			messages.email = ''
			isValid = false
		} else {
			messages.email = 'Please enter a valid email address'
			isValid = false
		}

		setMessages(messages)

		if (isValid) {
			onUpdateValidation({ key: 2, value: 1 })
		} else {
			onUpdateValidation({ key: 2, value: 0 })
		}
	}, [data])

	const formatPhone = (number) => {
		if (!number) return ''

		number = number.replace(/\D/g, '')

		let areaCode = ''
		let mid = ''
		let last = ''

		if (number.length >= 1 && number.length <= 3) {
			areaCode = number.substring(0, 3)
		} else if (number.length >= 4 && number.length <= 6) {
			areaCode = number.substring(0, 3)
			mid = number.substring(3, 6)
		} else if (number.length > 6) {
			areaCode = number.substring(0, 3)
			mid = number.substring(3, 6)
			last = number.substring(6, 10)
		}

		let formattedNumber = ''
		if (areaCode && !mid) formattedNumber += `${areaCode}`
		if (areaCode && mid) formattedNumber += `(${areaCode}) ${mid}`
		if (last) formattedNumber += `-${last}`

		return formattedNumber
	}

	const validatePhone = (e) => {
		const numericValue = e.target.value.replace(/\D/g, '')

		if (numericValue.length <= 10) {
			onChange({ target: { name: e.target.name, value: numericValue } })
		}
	}

	const sharedInputClass =
		'flex flex-col  space-y-0 space-x-0 pt-1 items-center justify-center  mb-2 '

	const labelClass =
		' bg-white rounded-t-sm w-full pl-4 pt-2 pb-0 font-semibold text-xs text-gray-400 '
	const inputClass =
		' w-full flex-1 py-1 font-semibold px-4 border-b text-bluDeepBlue border-bluDarkTeal mb-4  focus:outline-none '

	return (
		<div className='max-w-[300px] mx-auto '>
			<div className={sharedInputClass}>
				<div className={labelClass}>First Name *</div>
				<input
					type='text'
					name='firstName'
					id='firstName'
					value={data.firstName || ''}
					onChange={onChange}
					className={inputClass}
					required
				/>
			</div>
			<div className={sharedInputClass}>
				<div className={labelClass}>Phone *</div>
				<input
					type='tel'
					name='phone'
					id='phone'
					value={formatPhone(data.phone) || ''}
					onChange={validatePhone}
					required
					className={inputClass}
				/>
			</div>

			<div className={sharedInputClass}>
				<div className={labelClass}>Email *</div>
				<input
					type='email'
					name='email'
					id='email'
					value={data.email || ''}
					onChange={onChange}
					required
					className={inputClass}
				/>
			</div>
		</div>
	)
}

export default StepTwo
